import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PdfThumbnail from '../../components/myde-react-components/src/common/FileType/Pdf_Thumbnail.svg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import clsx from 'clsx';

import {
  colorPalette,
  FilePreviewType,
  TButton,
  TCheckbox,
  TDocumentViewModal,
  theme,
  TLoader,
} from '../myde-react-components';

interface PreviewFileByTypeProps {
  files: FilePreviewType[];
  isEdit?: boolean;
  isMultiple?: boolean;
  isDownloadable?: boolean;
  updateFiles?: (files: FilePreviewType[]) => void;
}

const useStyles = makeStyles({
  previewContainer: {
    width: '5.625rem',
    height: '4.375rem',
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '4px !important',
  },
  imgPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  truncateText: {
    width: '5.625rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const PreviewFileByType = ({
  files,
  isEdit,
  isMultiple,
  isDownloadable = false,
  updateFiles,
}: PreviewFileByTypeProps) => {
  // Constants
  const classes = useStyles();

  // State Variables
  const [filePreview, setFilePreview] = useState([] as FilePreviewType[]);
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState({} as FilePreviewType);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docToDelete, setDocToDelete] = useState([] as FilePreviewType[]);
  const [docNameToDelete, setDocNameToDelete] = useState([] as string[]);
  const [isFilesUpdated, setFileUpdateFlag] = useState(false);

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      const filesCopy = files?.map((file: FilePreviewType, index: number) => {
        return { index: index, ...file };
      });
      setFilePreview(filesCopy);
    } else {
      setFilePreview([] as FilePreviewType[]);
    }
  }, [files]);

  useEffect(() => {
    const tempArray = [] as string[];

    docToDelete?.forEach((doc) => {
      if (doc?.name) {
        tempArray?.push(doc?.name);
      }
    });

    setDocNameToDelete(tempArray);
  }, [docToDelete]);
  useEffect(() => {
    if (isFilesUpdated && updateFiles) {
      updateFiles(filePreview);
    }
    setFileUpdateFlag(false);
  }, [isFilesUpdated]);

  // Methods
  const showDocument = (item: FilePreviewType) => {
    setPreview(item);
    setShowModal(true);
  };

  const handleSelectClick = () => {
    setShowDelete(!showDelete);
    setDocToDelete([]);
  };

  const handleCheckBox = (selectedDoc: FilePreviewType) => {
    const deleteDocNameList = [...docNameToDelete];
    const deleteDocList = [...docToDelete];
    const selectedDocIndex = deleteDocList?.findIndex((file) => selectedDoc?.name === file?.name);
    const selectedDocNameIndex = deleteDocNameList?.indexOf(selectedDoc?.name);
    if (selectedDocNameIndex === -1) {
      selectedDoc?.name && deleteDocNameList?.push(selectedDoc?.name);
      deleteDocList.push(selectedDoc);
      docNameToDelete.push(selectedDoc?.name);
    } else {
      deleteDocNameList?.splice(selectedDocNameIndex, 1);
      deleteDocList?.splice(selectedDocIndex, 1);
    }
    setDocToDelete(deleteDocList);
    setDocNameToDelete(docNameToDelete);
  };

  const deleteHandler = () => {
    setLoading(true);
    const filePreviewData = Object.assign([], filePreview);
    const docToDeleteData = Object.assign([], docToDelete);

    const onlyInPreview = uniqueDocName(filePreviewData, docToDeleteData);

    const result = [...onlyInPreview];
    setFilePreview(result);
    setFileUpdateFlag(true);
    setShowDelete(false);
    setLoading(false);
  };

  const isSameFile = (data: FilePreviewType, data2: FilePreviewType) => data?.name === data2?.name;

  const uniqueDocName = (compareFromObj: any, compareToObj: any) =>
    compareFromObj.filter(
      (compareFrom: any) => !compareToObj.some((compareTo: any) => isSameFile(compareFrom, compareTo)),
    );

  const handleDownload = () => {
    window.open(preview?.preview);
  };

  return (
    <>
      <TLoader loading={loading} />
      <Box className="w-100">
        {isEdit && isMultiple && filePreview?.length > 0 && (
          <Box className="flex-basic-space-between" sx={{ my: 1 }}>
            <Box>
              <TButton btnText={!showDelete ? 'Select' : 'Deselect'} variant="text" onClick={handleSelectClick} />
            </Box>
            <Box>
              <IconButton className={'icon-delete'} disabled={!docToDelete?.length} onClick={() => deleteHandler()} />
            </Box>
          </Box>
        )}
        <Box
          sx={{ mt: 2 }}
          className={filePreview?.length > 1 ? 'category-scroll flex-basic-start' : 'flex-basic-center'}
        >
          {filePreview?.map((item: FilePreviewType, index: number) => (
            <Box key={index}>
              <Box>
                <Box className="p-relative">
                  <Box className="checkboxWithImageSelect">
                    {showDelete && <TCheckbox onChange={() => handleCheckBox(item)} />}
                  </Box>

                  {item?.type?.includes('video') ? (
                    <Box
                      sx={{ mr: filePreview?.length > 1 ? theme.spacing(5) : 0 }}
                      className="cursorPointer"
                      onClick={() => showDocument(item)}
                    >
                      <Box className={clsx(classes.previewContainer, 'flex-basic-center')} sx={{ mt: 1 }}>
                        <PlayCircleOutlineIcon className="textColor-200" />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                          {item?.name}
                        </Box>
                      </Box>
                    </Box>
                  ) : item?.type?.includes('pdf') ? (
                    <Box sx={{ ml: 1 }} className="cursorPointer" onClick={() => showDocument(item)}>
                      <Box className="flex-basic-center" sx={{ mt: 1 }}>
                        <img src={PdfThumbnail} className="textColor-200" />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                          {item?.name}
                        </Box>
                        <Box className="text-extra-small textColor-300">{item?.file?.lastModified}</Box>
                      </Box>
                    </Box>
                  ) : item?.type?.includes('image') ? (
                    <Box
                      sx={{ mr: filePreview?.length > 1 ? theme.spacing(5) : 0 }}
                      className="cursorPointer"
                      onClick={() => showDocument(item)}
                    >
                      <Box className={clsx(classes.previewContainer, 'flex-basic-center')} sx={{ mt: 1 }}>
                        <img src={item?.preview} className={classes.imgPreview} alt="Uploaded Image" />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                          {item?.name}
                        </Box>
                        <Box className="text-extra-small textColor-300">{item?.file?.lastModified}</Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
          <TDocumentViewModal
            open={showModal}
            file={preview}
            showDownloadIcon={isDownloadable}
            closeModal={() => setShowModal(false)}
            onDownload={handleDownload}
          />
        </Box>
      </Box>
    </>
  );
};

export default PreviewFileByType;
