import React, { useEffect, useState } from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../providers/custom-theme-provider';
import ContainerCard from '../../../components/common/ContainerCard';
import DocumentListing from '../../../components/common/DocumentListing';
import { colorPalette, TLoader, TButton, TSwitch, theme } from '../../../components/myde-react-components';
import {
  getSupplementalIdProcessDetails,
  customIdProcessDetails,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import { ListingType } from '../../../types/commonTypes';
import { formatDate } from '../../../utils/utils';
import { selectSelectedIdProcessDetails } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { linkCustomIdProcessToMerchantIdRequirement } from '../../../api/idProcesses';
import { CustomIdProcessType } from '../../../types/inviteTypes';
import { CUSTOM_ID_REQUIREMENTS_LINK_UNLINK } from '../../../constants/constants';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    fontSize: '3.8rem !important',
  },
  noCustomMessageContainer: {
    width: '100%',
    height: '600px',
  },
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
  bottomShadow: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
}));

const CustomDocumentCategoryList = () => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();

  // State Variables
  const [customProcessList, setCustomProcessList] = useState([] as ListingType[]);
  const [selectedCustomProcess, setSelectedCustomProcess] = useState([] as string[]);
  const [customData, setCustomData] = useState({} as CustomIdProcessType);
  const [isUnCategorized, setIsUnCategorized] = useState(false);
  const [hasAnyUnCategorizedProcess, setHasAnyUnCategorizedProcess] = useState(false);

  // Selectors
  const { loading, supplementalIdProcessList } = useSelector(customIdProcessDetails);
  const { selectedCustomIdProcess } = useSelector(selectSelectedIdProcessDetails);
  // Use Effects
  useEffect(() => {
    const params = {
      ordering: '-created_on',
    };
    dispatch(getSupplementalIdProcessDetails(params));
  }, []);

  useEffect(() => {
    if (selectedCustomIdProcess?.id) {
      setCustomData(selectedCustomIdProcess);
    }
  }, [selectedCustomIdProcess]);

  useEffect(() => {
    if (supplementalIdProcessList?.length > 0) {
      const data: Array<ListingType> = supplementalIdProcessList?.map((item: SupplementalIdProcessResults) => {
        return {
          id: item?.id,
          title: item?.process_name,
          subtitle: `${formatDate(item?.created_on)} ${item?.process_data?.process_category && '|'} ${
            item?.process_data?.process_category
          }`,
          icon: 'icon-document icon-size-24 textColor-100',
        };
      });
      const hasUnCategorizedProcess = supplementalIdProcessList?.filter(
        (item) => !item?.process_data?.process_category,
      );
      setHasAnyUnCategorizedProcess(hasUnCategorizedProcess?.length > 0);
      setCustomProcessList(data);
    } else {
      setCustomProcessList([] as ListingType[]);
    }
  }, [supplementalIdProcessList]);

  // Methods
  const navigateTo = () => {
    routeTo(ROUTES.INDEX, true, 'Custom ID');
  };
  const navigateToInvite = () => {
    routeTo(ROUTES.VIEW_INVITE, false);
  };

  const filterCustomProcesses = (data: string) => {
    const payload = {
      ordering: '-created_on',
      process_name: data,
    };
    dispatch(getSupplementalIdProcessDetails(payload));
  };

  const setSelectedData = (data: string[]) => {
    setSelectedCustomProcess(data);
  };

  const linkCustomIdProcess = async () => {
    const formData = {
      action: CUSTOM_ID_REQUIREMENTS_LINK_UNLINK.LINK,
      merchant_id_requirement_id: customData?.id,
      account_id_process_id: selectedCustomProcess[0],
    };
    try {
      const result = await linkCustomIdProcessToMerchantIdRequirement(formData);
      if (Object.keys(result)?.length > 0) {
        navigateToInvite();
      }
    } catch (error) {}
  };

  const handleSwitchChange = () => {
    const data = !isUnCategorized
      ? {
          ordering: '-created_on',
          has_process_category: isUnCategorized,
        }
      : { ordering: '-created_on' };
    setIsUnCategorized(!isUnCategorized);
    dispatch(getSupplementalIdProcessDetails(data));
  };

  const renderActions = () => {
    return (
      <Box className="flex-basic-space-around">
        <TButton
          icon={<Box className="icon-add" sx={{ pr: 1 }} />}
          btnText={'Add New'}
          variant={'text'}
          onClick={navigateTo}
        />
        <TButton
          icon={<Box className="icon-attach" sx={{ pr: 1 }} />}
          btnText={'Attach'}
          variant={'text'}
          disabled={selectedCustomProcess?.length === 0}
          onClick={linkCustomIdProcess}
        />
      </Box>
    );
  };

  const renderToggle = () => {
    return (
      <>
        {hasAnyUnCategorizedProcess ? (
          <Box sx={{ my: 2, mx: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box className="text-large font-weight-semibold textColor-300" sx={{ mt: theme.spacing(1) }}>
                  {t('customDocuments')}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box className="flex-basic-center">
                  <TSwitch themed value={isUnCategorized} checked={isUnCategorized} onChange={handleSwitchChange} />
                  <Box
                    className={clsx({
                      ['text-medium font-weight-semibold']: true,
                      [classes.btnColor]: isUnCategorized,
                      [classes.disabledState]: !isUnCategorized,
                    })}
                  >
                    {'Uncategorized'}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderRequirements = () => {
    return (
      <Box className="w-100 cardHeight">
        <DocumentListing
          list={customProcessList}
          categorySelectionToggle={renderToggle()}
          searchList={filterCustomProcesses}
          setSelectedData={setSelectedData}
        />
      </Box>
    );
  };

  return (
    <>
      <TLoader loading={loading} />
      {customProcessList?.length > 0 ? (
        <Box>
          <Box className="p-relative stickyBoxHeight">
            <ContainerCard content={renderRequirements()} hasBottomShadow={true} />
          </Box>
          <Box sx={{ p: 2 }} className={clsx(classes.bottomShadow, 'stickyFooterActions')}>
            {renderActions()}
          </Box>
        </Box>
      ) : (
        <ContainerCard content={renderRequirements()} />
      )}
    </>
  );
};

export default CustomDocumentCategoryList;
