import React, { useState, useEffect } from 'react';
import { Box, Chip, List, ListItem, ListItemIcon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  colorPalette,
  ID_PROCESS_STATUS,
  PROCESS_IDS,
  PROCESS_KEYS_WITH_PROCESS_ID,
  theme,
} from '../../components/myde-react-components';
import {
  ADDRESS_ICONS,
  BIOMETRIC_ICONS,
  IDENTITY_DOC_ICONS,
  INCOME_VERIFICATION_ICONS,
  KYC_AML_ICONS,
  PROFILE_ICONS,
  SIGNATURE_VERIFICATION_ICONS,
  SUPPLEMENTAL_DOCUMENTS_ICONS,
  TAX_ID_ICONS,
  CUSTOM_DOCUMENTS,
  ID_PROCESS_STATUS_SHOW_TEXT,
  ACCESS_LEVEL,
  ACCREDITATION,
  FORMATION_DOCUMENTS,
  LETTER_OF_GOOD_STANDING_ICONS,
  OWNERSHIP_DOCUMENT,
  IRS_LETTER_EVIDENCING_TAX_ID,
  BENEFICIAL_OWNER_DOCUMENT_VALUES,
} from '../../constants/constants';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { IconStatusType } from '../../types/idProcessTypes';
import { setColorIdProcess } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelectedIdProcessData,
  resetSelectedIdProcessDetails,
  setCustomIdProcess,
  setIdProcessKey,
  setParentIdProcess,
} from '../../redux/feature/idProcess/mainIdProcessSlice';
import { getIdProcessDetails, selectDashboardDetails } from '../../redux/feature/dashboard/dashboardSlice';
import { useTheme } from '../../providers/custom-theme-provider';
import { getEntityIdProcesses, selectEntity, setEntityUiId } from '../../redux/feature/entity/entitySlice';
import { selectInviteDashboardDetails } from '../../redux/feature/dashboard/invitationSlice';
import { ProcessType } from '../../types/invitationTypes';
import { setHideIdShareTab } from '../../redux/feature/idProcess/idProcessShareSlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    '& .MuiListItem-root': {
      padding: '24px',
    },
  },
  listIcon: {
    '& .MuiListItemIcon-root': {
      minWidth: '0px !important',
      marginRight: '20px',
    },
  },
  listItem: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    borderBottom: `1px solid ${colorPalette.border.card}`,
    cursor: 'pointer',
  },
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  chipWrapper: {
    '& .MuiChip-root': {
      border: '1px solid',
      borderColor: `${colorPalette.typoText.unSelected}!important`,
      background: `${colorPalette.typoText.lighten3}!important`,
    },
    '& .MuiChip-label': {
      color: `${colorPalette.typoText.darken3}!important`,
      font: 'normal normal 0.75rem/18px Montserrat !important',
    },
  },
}));

interface IdProcessProps {
  list: any[];
  simpleList: boolean;
  titleKey: string;
  subtitleKey: string;
  hasLongSubtitle?: boolean;
  isCustomProcess?: boolean;
}

const CommonIdProcessList = ({ list, simpleList, titleKey, isCustomProcess = false }: IdProcessProps) => {
  //Constants
  const { currentThemes } = useTheme();
  const { t } = useTranslation(['common', 'inviteFlow']);
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //State
  const [idProcesses, setIdProcesses] = useState<Array<any>>([]);
  const idProcessStatusCheck = [
    ID_PROCESS_STATUS.IN_PROGRESS,
    ID_PROCESS_STATUS.SUBMITTED,
    ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ];

  //Redux
  const { idProcessList } = useSelector(selectDashboardDetails);
  const { entityIdProcessList } = useSelector(selectEntity);
  const { checkIsEntity } = useSelector(selectEntity);
  const { selectedApplication, ownerIdProcessList } = useSelector(selectInviteDashboardDetails);

  //useEffects
  useEffect(() => {
    dispatch(setHideIdShareTab(false));
    if (checkIsEntity) {
      dispatch(getEntityIdProcesses(selectedApplication?.account?.trellis_uid));
      dispatch(setEntityUiId(selectedApplication?.account?.trellis_uid));
    } else {
      dispatch(getIdProcessDetails());
    }
  }, []);

  useEffect(() => {
    if (idProcessList?.length > 0) {
      setIdProcesses(idProcessList);
    }
  }, [idProcessList]);

  useEffect(() => {
    dispatch(clearSelectedIdProcessData());
  }, []);

  //Methods
  const gotToSpecificProcess = (element: any, isCustom: boolean) => {
    let processKey = '';
    let title = '';
    dispatch(resetSelectedIdProcessDetails());
    if (isCustom) {
      dispatch(setCustomIdProcess(element));
    } else {
      if (entityIdProcessList?.length > 0 || idProcesses?.length > 0) {
        let parentProcessData: any = {};
        const processList = checkIsEntity ? entityIdProcessList : idProcesses;
        parentProcessData = processList?.find((item) => {
          if (item?.process_id === element?.parent_process_id) {
            return item;
          }
        });
        dispatch(setParentIdProcess(parentProcessData));
      }
    }
    const selectedProcessId = [PROCESS_IDS.DRIVERS_LICENSE, PROCESS_IDS.PASSPORT].includes(element?.process_id)
      ? PROCESS_IDS.DOCUMENT_VERIFICATION
      : element?.process_id;

    if (!isCustom) {
      const idProcessData: any = PROCESS_KEYS_WITH_PROCESS_ID.find((item) =>
        item?.id_processes?.includes(element?.process_id),
      );
      processKey = idProcessData.process_key || '';
      title = idProcessData?.process_title || '';
      dispatch(setIdProcessKey(processKey));
    }
    const query = { id: selectedProcessId, isEntity: checkIsEntity };
    if (isCustom) {
      routeTo(ROUTES.CUSTOM_DOCUMENTS_DESCRIPTION, true, 'Custom ID');
    } else if (element.parent_process_id === 0) {
      routeTo(ROUTES.BENEFICIAL_OWNER_DOCUMENT, true, title);
    } else {
      routeTo(ROUTES.ID_PROCESS, true, title, query);
    }
  };

  const getIconByStatus = (status: string, type: IconStatusType) => {
    if (
      status === ID_PROCESS_STATUS.PASSED ||
      status === ID_PROCESS_STATUS.VERIFIED ||
      status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
      status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW
    ) {
      return type.SUCCESS;
    } else if (status === ID_PROCESS_STATUS.FAILED || status === ID_PROCESS_STATUS.VERIFICATION_FAILED) {
      return type.ERROR;
    } else {
      return type.NORMAL;
    }
  };

  const getIconName = (id: number, status: string) => {
    switch (id) {
      case 0:
        return getIconByStatus(status, KYC_AML_ICONS);
      case 50:
        return getIconByStatus(status, PROFILE_ICONS);
      case 100:
        return getIconByStatus(status, TAX_ID_ICONS);
      case 200:
        return getIconByStatus(status, IDENTITY_DOC_ICONS);
      case 300:
        return getIconByStatus(status, BIOMETRIC_ICONS);
      case 600:
        return getIconByStatus(status, ADDRESS_ICONS);
      case 400:
        return getIconByStatus(status, SIGNATURE_VERIFICATION_ICONS);
      case 500:
        return getIconByStatus(status, SUPPLEMENTAL_DOCUMENTS_ICONS);
      case 250:
        return getIconByStatus(status, KYC_AML_ICONS);
      case 700:
        return getIconByStatus(status, INCOME_VERIFICATION_ICONS);
      case 800:
        return getIconByStatus(status, ACCREDITATION);

      case 5000:
        return getIconByStatus(status, PROFILE_ICONS);
      case 20000:
        return getIconByStatus(status, SUPPLEMENTAL_DOCUMENTS_ICONS);
      case 70000:
        return getIconByStatus(status, ACCREDITATION);
      case 50000:
        return getIconByStatus(status, LETTER_OF_GOOD_STANDING_ICONS);
      case 30000:
        return getIconByStatus(status, FORMATION_DOCUMENTS);
      case 10000:
        return getIconByStatus(status, IRS_LETTER_EVIDENCING_TAX_ID);
      case 40000:
        return getIconByStatus(status, OWNERSHIP_DOCUMENT);
      default:
        return getIconByStatus(status, CUSTOM_DOCUMENTS);
    }
  };

  const getIconTemplate = (status: string, processId: number) => {
    return (
      <Box style={{ color: setColorIdProcess(status) }} className="flex-basic-center">
        {idProcessStatusCheck?.includes(status) && status !== ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW ? (
          <Box className="p-relative custom-progress-icon">
            <Box className="progress-spinner"></Box>
            <span className={clsx(`${getIconName(processId, status)}`)} />
          </Box>
        ) : (
          <Box>
            <span className={clsx(`${getIconName(processId, status)}`, 'icon-size-32')} />
          </Box>
        )}
      </Box>
    );
  };

  //HTML
  return (
    <>
      <List className={classes.root}>
        {list.map((element: any, index: number) => (
          <ListItem
            className={clsx({
              [classes.listIcon]: simpleList,
              [classes.listItem]:
                element.status === ID_PROCESS_STATUS.SUBMITTED ||
                element.status === ID_PROCESS_STATUS.PASSED ||
                element.status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
                element.status === ID_PROCESS_STATUS.FAILED ||
                element.status === ID_PROCESS_STATUS.IN_PROGRESS ||
                element.status === ID_PROCESS_STATUS.IN_DRAFT ||
                element.status === ID_PROCESS_STATUS.PENDING_VERIFICATION ||
                element.status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW ||
                !element.status,
            })}
            sx={{ py: 2 }}
            key={index}
            onClick={() => gotToSpecificProcess(element, isCustomProcess)}
          >
            <Box className="d-flex w-100">
              <Box>
                <Box sx={{ pt: theme.spacing(1) }}>
                  {simpleList && (
                    <ListItemIcon>
                      {element.status === ID_PROCESS_STATUS.PASSED ||
                      element.status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
                      element.status === ID_PROCESS_STATUS.SUBMITTED ||
                      element.status === ID_PROCESS_STATUS.FAILED ||
                      element.status === ID_PROCESS_STATUS.IN_PROGRESS ||
                      element.status === ID_PROCESS_STATUS.IN_DRAFT ||
                      element.status === ID_PROCESS_STATUS.VERIFIED ||
                      element.status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW ||
                      element.status === ID_PROCESS_STATUS.PENDING_VERIFICATION
                        ? getIconTemplate(element.status, element.parent_process_id)
                        : getIconTemplate(element.status, element.parent_process_id)}
                    </ListItemIcon>
                  )}
                </Box>
              </Box>
              <Box className={clsx('flex-basic-space-between w-100')}>
                <Box sx={{ pr: 1 }}>
                  <Box
                    className={clsx({
                      ['textColor-200 text-medium font-weight-semibold']: element.status,
                      ['textColor-300 text-medium font-weight-semibold']: !element.status,
                    })}
                    sx={{
                      pb: checkIsEntity ? theme.spacing(0) : theme.spacing(2),
                    }}
                  >
                    {element[titleKey]}
                  </Box>
                  {isCustomProcess ? (
                    <>
                      {!checkIsEntity && (
                        <Box className="text-small textColor-300">Visibility: {ACCESS_LEVEL.SOURCE}</Box>
                      )}
                    </>
                  ) : (
                    <Box>
                      {!checkIsEntity && (
                        <Box
                          sx={{ pb: theme.spacing(1) }}
                          className="textColor-200 text-small font-weight-regular"
                        >{`Visibility: ${element?.access_level}`}</Box>
                      )}
                      {element?.key === BENEFICIAL_OWNER_DOCUMENT_VALUES.KEY && (
                        <Box sx={{ pt: 1 }}>
                          {ownerIdProcessList?.map((item: ProcessType, index: number) => (
                            <Box key={index} sx={{ pb: 1 }} className="text-small textColor-300">
                              {item?.title}
                            </Box>
                          ))}
                        </Box>
                      )}
                      {element?.details?.note && (
                        <Box sx={{ pt: theme.spacing(2) }} className="text-small textColor-200">
                          {element?.details?.note}
                        </Box>
                      )}
                      {!element?.details?.is_mandatory && (
                        <Box sx={{ pt: theme.spacing(2) }}>
                          <span className={clsx('customChips', classes.chipWrapper)}>
                            <Chip label={t('optionalLabel', { ns: 'inviteFlow' })} />
                          </span>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
                <Box className="flex-basic-end">
                  <Box>
                    {!isCustomProcess && idProcessStatusCheck.includes(element?.status) && (
                      <Box className={clsx('text-extra-small font-weight-medium textColor-300')}>
                        {ID_PROCESS_STATUS_SHOW_TEXT[element?.status]}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default CommonIdProcessList;
