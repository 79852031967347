import React, { useState, useEffect } from 'react';
import { Grid, Box, Theme, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from '../../../providers/custom-router-provider';
import { useTranslation } from 'react-i18next';

import IdProcessList from './IdProcessList';
import { useTheme } from '../../../providers/custom-theme-provider';
import { colorPalette, TButton } from '../../../components/myde-react-components';
import * as ROUTES from '../../../constants/routes';
import {
  customIdProcessDetails,
  getSupplementalIdProcessDetails,
  setSelectedCategory,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import { selectSession } from '../../../redux/feature/common/commonSlice';
import { ID_PROCESS_CATEGORY } from '../../../constants/constants';

interface StyleProps {
  currentThemes: Theme;
  hideAccordion?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  tabsItem: {
    '& .Mui-selected': {
      padding: 12,
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
  checked: {
    borderStyle: 'none',
    border: 'none',
    borderRadius: '10px',
    background: colorPalette.containerBack.lighten3,
    cursor: 'pointer',
    boxShadow: `0px 0px 11px ${colorPalette.typoText.lighten2}`,
  },
  listItem: {
    border: `1px solid ${colorPalette.typoText.darken1}`,
    borderRadius: '10px',
    borderStyle: 'none',
    cursor: 'pointer',
  },
  btnColor: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

const IdProcesses = () => {
  const { currentThemes } = useTheme();
  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const [active, setActive] = useState(false);
  const [customIdProcessList, setCustomIdProcessList] = useState<Array<SupplementalIdProcessResults>>([]);
  const { supplementalIdProcessList } = useSelector(customIdProcessDetails);

  //useEffects
  useEffect(() => {
    const params = {
      ordering: '-created_on',
    };
    dispatch(getSupplementalIdProcessDetails(params));
  }, []);

  useEffect(() => {
    if (supplementalIdProcessList) {
      setCustomIdProcessList(supplementalIdProcessList);
    }
  }, [supplementalIdProcessList]);

  const handleClick = () => {
    setActive(!active);
  };

  //methods
  const handleCustomDocuments = () => {
    routeTo(ROUTES.CUSTOM_DOCUMENTS, true, 'Custom ID');
  };
  const navigateTo = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    routeTo(ROUTES.INDEX, true, 'Custom ID');
  };
  //Redux values
  const { showCard } = useSelector(selectSession);

  //HTML
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Box>
        <IdProcessList category={ID_PROCESS_CATEGORY.BASIC} />
        {customIdProcessList?.length > 0 && (
          <Box>
            <List>
              <ListItem
                className={clsx(classes.checked, classes.listItem, 'cursorPointer')}
                sx={{ py: 3, my: 1.5 }}
                onClick={handleCustomDocuments}
              >
                <Box sx={{ pr: 3 }}>
                  <span className="icon-letter-evidence icon-size-32 textColor-300"></span>
                </Box>
                <Box className="flex-basic-space-between w-100">
                  <Box className="textColor-300 text-large font-weight-semibold">{t('customDocuments')}</Box>
                </Box>
              </ListItem>
            </List>
          </Box>
        )}
        <Box className="flex-basic-end">
          <TButton onClick={handleClick} btnText={active ? 'Show Less' : 'Show More'} />
        </Box>
        {active ? <IdProcessList category={ID_PROCESS_CATEGORY.ADVANCED} hideAccordion={true} /> : <></>}

        {!showCard && (
          <>
            <Box
              className={clsx('floating-btn flex-basic-center cursorPointer', classes.btnColor)}
              onClick={() => navigateTo()}
            >
              <span className="icon-add"></span>
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default IdProcesses;
