import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import {
  colorPalette,
  FilePreviewType,
  TButton,
  TCard,
  TCommonAvatar,
  TDocumentViewModal,
  theme,
  TLoader,
} from '../../../components/myde-react-components';
import UserIcon from '../../../components/common/UserIcon';
import { useTranslation } from 'react-i18next';
import { getProfileDetails, selectProfile } from '../../../redux/feature/profile/profileSlice';
import { useTheme } from '../../../providers/custom-theme-provider';
import clsx from 'clsx';
import { getEntityList, selectEntity, setSelectedEntity } from '../../../redux/feature/entity/entitySlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  addProfilePicBtn: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    color: `${colorPalette.typoText.lighten3} !important`,
  },
});

const ProfileInfoIndex = () => {
  //Constant
  const { currentThemes } = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const styleProps = {
    currentThemes,
    matches,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  //Redux
  const { profileData, loading } = useSelector(selectProfile);
  const { entityList } = useSelector(selectEntity);

  //State
  const [imageInFullView, setImageInFullView] = useState(false);
  const [preview, setPreview] = useState({} as FilePreviewType);

  useEffect(() => {
    dispatch(getProfileDetails());
    dispatch(getEntityList());
  }, []);

  //Methods
  const gotoEntityDetails = (entity: any) => {
    dispatch(setSelectedEntity(entity));
    routeTo(ROUTES.ENTITY_PROFILE, true, entity?.name || 'Entity');
  };

  const gotoEditProfilePic = () => {
    routeTo(ROUTES.EDIT_ENTITY_PROFILE_PICTURE, true, 'Edit Profile Picture');
  };

  const openImageInFullView = () => {
    if (!profileData?.avatar_url) return;
    const imagePreview: FilePreviewType = {
      name: '',
      type: 'image/*',
      preview: profileData?.avatar_url,
      size: 0,
    };
    setPreview(imagePreview);
    setImageInFullView(true);
  };

  const handleCloseModel = () => {
    setImageInFullView(false);
  };

  //HTML
  return (
    <Box sx={{ pt: 8 }}>
      <>
        <TCard sx={{ mt: theme.spacing(3), borderRadius: '10px' }}>
          <TLoader loading={loading} />
          <Box sx={{ px: 3, pb: 3, mt: 3 }} className="flex-column-center">
            <Box className="p-relative">
              <Box className={clsx({ ['cursorPointer']: profileData?.avatar_url })} onClick={openImageInFullView}>
                <UserIcon imgBorderRadius="100%" iconSize="133px" />
              </Box>
              <TButton
                className={clsx('addProfileImgBtn p-absolute', classes.addProfilePicBtn)}
                icon={<Box className="icon-add"></Box>}
                onClick={gotoEditProfilePic}
                aria-label={'add profile pic'}
              />
            </Box>
            <Box className="textColor-200 text-medium font-weight-semibold">{profileData?.name || ''}</Box>
            <Box sx={{ mt: theme.spacing(1) }} className="textColor-300 text-small">
              {profileData?.email}
            </Box>
          </Box>
          <Divider sx={{ mb: 3 }} />
          <Box sx={{ px: 3, pb: 3, mt: 3 }}>
            <Box sx={{ mb: theme.spacing(7) }} className="textColor-300 text-small">
              {t('entities')}
            </Box>
            {entityList?.length > 0 ? (
              entityList?.map((entity, index) => (
                <Box key={entity?.id}>
                  <Box className="flex-basic-start">
                    <TCommonAvatar imageUrl={entity?.avatar_url} avatarText={entity?.name} iconSize={'40px'} />
                    <Box
                      sx={{ ml: 2 }}
                      className="textColor-200 text-large font-weight-medium cursorPointer"
                      onClick={() => gotoEntityDetails(entity)}
                    >
                      {entity?.name}
                    </Box>
                  </Box>
                  {entityList?.length > 1 && index + 1 !== entityList?.length && (
                    <Divider sx={{ my: theme.spacing(5) }} />
                  )}
                </Box>
              ))
            ) : (
              <Box className="text-h3 font-weight-semibold textColor-300 text-center">
                {t('nodataAvailable', { ns: 'userViewMessage' })}
              </Box>
            )}
          </Box>
        </TCard>
      </>

      <TDocumentViewModal open={imageInFullView} file={preview} closeModal={handleCloseModel} title={'Profile Image'} />
    </Box>
  );
};

export default ProfileInfoIndex;
