import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  TTextField,
  TButton,
  colorPalette,
  theme,
  TPasswordStrengthMeter,
  TLoader,
} from '../../../components/myde-react-components';
import { ChangePasswordFormSchema, ChangePasswordFormSchemaPayload } from './validation';
import { fontStyle } from '../../../styles/style';
import { passwordValidations } from '../../../constants/constants';
import { useTheme } from '../../../providers/custom-theme-provider';
import AuthLayout from '../../../layout/AuthLayout';
import useQuery from '../../../hooks/useQuery';
import { ChangePasswordType } from '../../../types/commonTypes';
import {
  changePasswordHandler,
  resetExpiredUserPassword,
  selectUserPasswordData,
  validateNewPassword,
} from '../../../redux/feature/profile/passwordSlice';
import { useAuth } from '../../../auth/auth-guard';
import { selectUser } from '../../../redux/feature/user/userSlice';

// Styles
const useStyles = makeStyles({
  containerCard: {
    borderRadius: '10px',
    border: `1px solid ${colorPalette.border.card}`,
    backgroundColor: colorPalette.containerBack.lighten3,
  },
  backIcon: { cursor: 'pointer', margin: '2px 8px', fontSize: `${fontStyle.fontSize.font24.fontSize} !important` },
  title: { lineHeight: '36px' },
});

// Main Class
const ChangePassword = () => {
  // Constants
  const { t } = useTranslation(['common', 'forgotPassword']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const query = useQuery();
  const userEmail = query.get('email');
  const { logout } = useAuth();

  const { handleSubmit, control, formState, setError, getValues, setValue } = useForm<ChangePasswordFormSchemaPayload>({
    resolver: zodResolver(ChangePasswordFormSchema),
    mode: 'onChange',
  });

  // Redux Values
  const { isPasswordUpdated, newPasswordValidationMsg, loading } = useSelector(selectUserPasswordData);
  const { userData } = useSelector(selectUser);
  const { errors, isValid, isSubmitting } = formState;

  // State Values
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState('');
  const [subTitle, setSubTitle] = useState('');

  // Use Effects
  useEffect(() => {
    if (isPasswordUpdated) {
      logout();
    }
  }, [isPasswordUpdated]);
  useEffect(() => {
    setNewPasswordErrorMsg(newPasswordValidationMsg);
  }, [newPasswordValidationMsg]);
  useEffect(() => {
    if (userEmail && userData?.days_to_password_expiry > 0) {
      setSubTitle(t('passwordExpiryMessage', {daysToExpire: userData?.days_to_password_expiry}))
    } else if (userEmail && userData?.days_to_password_expiry <= 0) {
      setSubTitle(t('passwordExpiredMessage'))
    } else {
      setSubTitle(t('resetPasswordPageSubTitle'));
    }
  }, [userEmail, userData]);

  // Methods
  const onSubmit = async () => {
    try {
      const requestPayload = getPasswordPaylolad();
      if (userEmail) {
        await dispatch(resetExpiredUserPassword(requestPayload));
      } else {
        await dispatch(changePasswordHandler(requestPayload));
      }
    } catch (err: any) {
      setError('new_password', { type: 'manual', message: err.password });
    }
  };
  const inputChange = (e: any, isError: boolean) => {
    setNewPassword(e?.target?.value);
    setIsPasswordError(isError);
    if (getValues('confirm_password')) {
      setValue('confirm_password', getValues('confirm_password'), { shouldValidate: true });
    }
  };
  const compareCurrentAndNewPasswords = () => {
    if (getValues('new_password')) {
      setValue('new_password', getValues('new_password'), { shouldValidate: true });
      setNewPassword(getValues('new_password'));
      setIsPasswordError(false);
    }
  };
  const validatePassword = (isError: boolean) => {
    if (!isError && userEmail) {
      dispatch(validateNewPassword(getPasswordPaylolad()));
    }
  };
  const getPasswordPaylolad = () => {
    const changePasswordData = getValues();
    const requestPayload: ChangePasswordType = {
      current_password: changePasswordData?.current_password,
      new_password: changePasswordData?.new_password,
    };
    if (userEmail) {
      requestPayload.email = userEmail;
    }
    return requestPayload;
  };

  // HTML
  return (
    <>
      <AuthLayout>
        <TLoader loading={loading} />
        <Box className={classes.containerCard}>
          <Box sx={{ pt: 2, px: 1, pb: 1 }}>
            <Box>
              <Box
                className={clsx(classes.title, 'text-large font-weight-semibold textColor-200 text-center')}
              >
                { t('resetPassword') }
              </Box>
              <Box
                className={clsx('text-medium font-weight-regular textColor-200 text-center')}
                sx={{ mt: 2 }}
              >
                { subTitle }
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 3, mt: 2 }}>
            <form>
              <Box>
                <Controller
                  name="current_password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TTextField
                      label="Current Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                      error={!!errors.current_password}
                      helperText={errors.current_password?.message}
                      onKeyUp={compareCurrentAndNewPasswords}
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Controller
                    name="new_password"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        sx={{ mt: theme.spacing(5) }}
                        label="New Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        onKeyUp={(event) => {
                          inputChange(event, !!errors.new_password);
                        }}
                        error={!!errors.new_password || !!newPasswordErrorMsg}
                        helperText={errors.new_password?.message || newPasswordErrorMsg}
                        {...field}
                        onBlur={() => validatePassword(!!errors.new_password)}
                      />
                    )}
                  />
                  <TPasswordStrengthMeter password={newPassword} isError={isPasswordError} />
                </Box>
                <Box>
                  <Controller
                    name="confirm_password"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        sx={{ mt: theme.spacing(4) }}
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        error={!!errors.confirm_password}
                        helperText={errors.confirm_password?.message}
                        {...field}
                      />
                    )}
                  />
                </Box>
                {!isValid && (
                  <Box sx={{ mb: 2, textAlign: 'left', mt: theme.spacing(5) }}>
                    <Box
                      className={'text-medium font-weight-medium'}
                      sx={{ color: currentThemes.palette.primary.main }}
                    >
                      {t('newPasswordContain', { ns: 'forgotPassword' })}
                    </Box>
                    {passwordValidations.map((validation, index) => (
                      <Box key={index} className={'text-medium passContentList'}>
                        {validation.message}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box className="flex-basic-center">
                <TButton
                  variant={!isValid ? 'outlined' : 'contained'}
                  type="submit"
                  btnWidthSize="button-w-240"
                  sx={{ mt: 2, mb: 1 }}
                  disabled={!isValid || isSubmitting || !!newPasswordErrorMsg}
                  color="primary"
                  btnText={'Update'}
                  onClick={handleSubmit(onSubmit)}
                ></TButton>
              </Box>
            </form>
          </Box>
        </Box>
      </AuthLayout>
    </>
  );
};

export default ChangePassword;
