import React, { useState } from 'react';
import { Box, Divider, InputAdornment } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { theme, TButton, TTextField, TRadio } from '../../components/myde-react-components';
import { ClickHandlerType, ListingType } from '../../types/commonTypes';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';

interface DocumentListingProps {
  list: ListingType[];
  searchList: (data: string) => void;
  setSelectedData?: (data: string[]) => void;
  categorySelectionToggle?: React.ReactNode;
  isMessagesSection?: boolean;
  setSelectedCommunication?: ClickHandlerType;
}

const useStyles = makeStyles({
  noCustomMessageContainer: {
    width: '100%',
    height: '600px',
  },
});

const DocumentListing = ({
  list,
  categorySelectionToggle = <></>,
  isMessagesSection = false,
  searchList,
  setSelectedData,
  setSelectedCommunication,
}: DocumentListingProps) => {
  // Constants
  const classes = useStyles();
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');
  // State Variables
  const [searchValue, setSearchValue] = useState('');
  const [uniqueIdList, setUniqueIdList] = useState([] as string[]);

  // Methods
  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event?.target?.value);
    searchList(event?.target?.value);
  };

  const navigateTo = () => {
    routeTo(ROUTES.INDEX, true, 'Custom ID');
  };

  const handleChange = (item: ListingType) => {
    const data = [...uniqueIdList];
    data?.splice(0, 1);
    data?.push(item?.id);
    setUniqueIdList(data);
    setSelectedData && setSelectedData(data);
  };

  const selectSpecificMessage = (messageDetails: ListingType) => {
    if (!setSelectedCommunication) return;
    setSelectedCommunication(messageDetails);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box>
        <TTextField
          variant="outlined"
          placeholder="Search"
          value={searchValue}
          onChange={updateSearchValue}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon-search textColor-400" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>{categorySelectionToggle}</Box>
      {list?.length > 0 ? (
        <Box className="documentCategoryList" sx={{ pb: 4, mt: theme.spacing(3) }}>
          {list?.map((item: ListingType, index: number) => {
            return (
              <Box key={index} sx={{ mr: list?.length > 4 ? 1 : 0 }}>
                <Box sx={{ py: theme.spacing(5) }}>
                  <Box
                    className={clsx('flex-basic-space-between', { ['cursorPointer']: isMessagesSection })}
                    onClick={() => selectSpecificMessage(item)}
                  >
                    <Box className="flex-basic-start">
                      <Box sx={{ pr: 3 }} className={clsx('icon-size-24 textColor-100', item?.icon)} />
                      <Box>
                        <Box
                          sx={{ pb: theme.spacing(1) }}
                          className={clsx('text-break-word textColor-200', { ['font-weight-semibold']: !item?.isRead })}
                        >
                          {item?.title}
                        </Box>
                        <Box className="text-small text-break-word">{item?.subtitle}</Box>
                      </Box>
                    </Box>
                    <Box>
                      {isMessagesSection ? (
                        <>{item?.hasDocuments && <Box className="icon-document-attached icon-size-16"></Box>}</>
                      ) : (
                        <TRadio
                          value={item.id}
                          checked={uniqueIdList?.includes(item?.id)}
                          onChange={() => handleChange(item)}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                {index < list?.length - 1 && <Divider sx={{ my: 1 }} />}
              </Box>
            );
          })}
        </Box>
      ) : (
        <>
          <Box className={clsx('text-center', classes.noCustomMessageContainer)} sx={{ p: 4 }}>
            <Box
              className={clsx('textColor-400 icon-size-72', {
                ['icon-letter-evidence']: !isMessagesSection,
                ['icon-messages']: isMessagesSection,
              })}
              sx={{ my: 4 }}
            />
            <Box className="textColor-300 text-h3 font-weight-semibold" sx={{ my: 4 }}>
              {t(isMessagesSection ? 'noDataAvailable' : 'noCustomDocuments')}
            </Box>
            {!isMessagesSection && (
              <TButton btnText={'Add New'} variant={'contained'} btnWidthSize="button-w-240" onClick={navigateTo} />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default DocumentListing;
