import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import useQuery from '../../hooks/useQuery';
import {
  getSentAssociationRequests,
  revokeEntityAssociationRequest,
  selectEntity,
} from '../../redux/feature/entity/entitySlice';
import { TButton, TCard, TCommonAvatar, theme } from '../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import { fontStyle } from '../../styles/style';
import { CreatedRequestType } from '../../types/entityTypes';
import { ENTITY_INVITE_REQUEST_TYPE, REQUEST_REVIEW_STATUS } from '../../constants/constants';
import ReviewInvite from './ReviewInvite';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';

const EntityInviteDetails = () => {
  const { selectedRequest } = useSelector(selectEntity);
  const query = useQuery();
  const requestType = query.get('type');
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  //state variables
  const [data, setData] = useState({} as CreatedRequestType);

  //useEffects
  useEffect(() => {
    if (Object.keys(selectedRequest)?.length > 0) {
      setData(selectedRequest);
    }
  }, [selectedRequest]);

  //methods
  const handleRevokeRequest = async () => {
    await dispatch(revokeEntityAssociationRequest(selectedRequest?.id));
    const data = {
      ordering: 'review_status',
      status_in: `{${REQUEST_REVIEW_STATUS.PENDING},${REQUEST_REVIEW_STATUS.REJECTED}}`,
    };
    await dispatch(getSentAssociationRequests(data));
    routeTo(ROUTES.ENTITY_INVITE);
  };

  return (
    <TCard>
      <Box sx={{ pt: theme.spacing(5), pb: 4 }} className="font-weight-semibold text-large text-center">
        {t('entityAssociationRequest')}
      </Box>
      <Box>
        <TCommonAvatar
          imageUrl={data?.requested_account?.avatar_url || ''}
          avatarText={data?.requested_account?.name || ''}
          iconSize="6.563rem"
          textSize={fontStyle.fontSize.font36.fontSize}
        />
      </Box>
      <Box sx={{ pt: 4, px: theme.spacing(5) }} className="text-center text-h2 textColor-200 font-weight-semibold">
        {requestType === ENTITY_INVITE_REQUEST_TYPE.SENT
          ? `${t('sentText')} ${data?.requested_account?.name}`
          : `${t('receivedText')} ${data?.requested_account?.name}`}
      </Box>
      <Box sx={{ pt: 5, pl: 3 }} className="text-small textColor-300">
        {t('entityTid')}
      </Box>
      <Box sx={{ pl: 3 }} className="text-medium font-weight-semibold">
        {data?.requested_account?.trellis_uid}
      </Box>
      <Box sx={{ pt: theme.spacing(5), pl: 3 }} className="text-small textColor-300">
        {t('entityName')}
      </Box>
      <Box sx={{ pl: 3 }} className="text-medium font-weight-semibold">
        {data?.requested_account?.name}
      </Box>
      <Divider sx={{ pt: theme.spacing(7) }} />
      <Box sx={{ pt: theme.spacing(5) }}>
        {requestType === ENTITY_INVITE_REQUEST_TYPE.SENT ? (
          <>
            <Box sx={{ px: theme.spacing(3) }} className="text-center text-medium">
              {t('resendRevokeText')}
            </Box>
            <Box sx={{ pt: theme.spacing(7) }} className="text-center">
              <TButton btnText="Resend" variant="contained" btnWidthSize={'button-w-240'} />
            </Box>
            <Box sx={{ pt: theme.spacing(5), pb: 1 }} className="text-center">
              <TButton btnText="Revoke" variant="text" onClick={handleRevokeRequest} />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ pl: 3, pb: theme.spacing(4) }} className="text-small textColor-300">
              {t('requests')}
            </Box>
            <Box sx={{ mb: 5, mx: theme.spacing(4) }}>
              <ReviewInvite trellis_uid={data?.requested_account?.trellis_uid} />
            </Box>
          </>
        )}
      </Box>
    </TCard>
  );
};

export default EntityInviteDetails;
